import * as React from 'react';
import { GatsbyHelmet } from '../utils/helmet';
import { DefaultLayout } from '../components/DefaultLayout';

const FourOhFour: React.FC = () => {
  return (
    <DefaultLayout>
      <GatsbyHelmet title="404"/>
      <p>FourOhFour!!</p>
    </DefaultLayout>
  );
};
export default FourOhFour;
